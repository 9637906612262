import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MdePopoverModule } from '@material-extended/mde';
import { TranslateModule } from '@ngx-translate/core';
import { HttpRequestInterceptor } from '../../services/http-interceptor.service';
import { MaterialUiModule } from '../material-ui/material-ui.module';
import { CommentsContainerComponent } from './components/comments/comments-container/comments-container.component';
import { CommentsOverlayComponent } from './components/comments/comments-overlay/comments-overlay.component';
import { CommentsService } from './components/comments/comments.service';
import { DataGridComponent } from './components/data-grid/data-grid.component';
import { DataModalComponent } from './components/data-modal/data-modal.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ConfirmDialogComponent } from './components/dialog-box/confirm-dialog/confirm-dialog.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ProcurantLoadingComponent } from './components/procurant-loading/procurant-loading.component';
import { ProductOverlayComponent } from './components/product-overlay/product-overlay.component';
import { ArraySortPipe, ParsePipes } from './pipes';
import { ComponentService } from './services/component.service';
import { DialogBoxService } from './services/dialog-box.service';
// import {TimeAgoPipe} from 'time-ago-pipe';
import { NgxUploaderModule } from 'ngx-uploader';
import { ActivateUserComponent } from './components/activate-user/activate-user.component';
import { CommonNotificationComponent } from './components/common-notification/common-notification.component';
import { CommonSnackbarComponent } from './components/common-snackbar/common-snackbar.component';
import { CommonDialogComponent } from './components/dialog-box/common-dialog/common-dialog.component';
import { CustomDatePickerComponent } from './components/custom-date-picker/custom-date-picker.component';
import { ProcurantTooltipComponent } from './components/procurant-tooltip/procurant-tooltip.component';
import { ToolTipRendererDirective } from './services/tool-tip-renderer.directive';
import { ProcurantOverlayDesignComponent } from './components/procurant-overlay-design/procurant-overlay-design.component';
import { ProcurantSelectComponent } from './components/procurant-select/procurant-select.component';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
@NgModule({
    declarations: [
        ParsePipes,
        ArraySortPipe,
        DatePickerComponent,
        CommentsContainerComponent,
        CommentsOverlayComponent,
        ProcurantLoadingComponent,
        DataModalComponent,
        DataGridComponent,
        MultiSelectComponent,
        ConfirmDialogComponent,
        CommonDialogComponent,
        ProductOverlayComponent,
        // TimeAgoPipe,
        ActivateUserComponent,
        CommonSnackbarComponent,
        CommonNotificationComponent,
        CustomDatePickerComponent,
        ProcurantTooltipComponent,
        ToolTipRendererDirective,
        ProcurantOverlayDesignComponent,
        ProcurantSelectComponent

    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialUiModule,
        FlexLayoutModule,
        MdePopoverModule,
        TranslateModule,
        NgxUploaderModule,
        TooltipsModule

    ],
    exports: [
        // TimeAgoPipe,
        ParsePipes,
        ArraySortPipe,
        DatePickerComponent,
        FlexLayoutModule,
        MdePopoverModule,
        TranslateModule,
        CommentsContainerComponent,
        CommentsOverlayComponent,
        ProcurantLoadingComponent,
        DataGridComponent,
        DataModalComponent,
        MultiSelectComponent,
        ConfirmDialogComponent,
        CommonDialogComponent,
        ProductOverlayComponent,
        CustomDatePickerComponent,
        ProcurantTooltipComponent,
        ProcurantOverlayDesignComponent,
        ToolTipRendererDirective,
        ProcurantSelectComponent,
        TooltipsModule
    ],
    providers: [
        HttpRequestInterceptor,
        DatePipe,
        CurrencyPipe,
        DecimalPipe,
        ParsePipes,
        CommentsService,
        ComponentService,
        DialogBoxService
    ]
})
export class SharedComponentsModule { }
