import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GridModule } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppToolbarsModule } from './modules/app-toolbars/app-toolbars.module';
import { DevLoginModule } from './modules/dev-login/dev-login.module';
import { RedirectGuard } from './services/redirect-guard';
// import { LoginComponent } from './modules/login/login.component';
import { MaterialUiModule } from './modules/material-ui/material-ui.module';
// import { OneLoginModule } from './modules/one-login/one-login.module';
import { PageNotFoundComponent } from './modules/shared-components/components/page-not-found/page-not-found.component';
import { SharedComponentsModule } from './modules/shared-components/shared-components.module';
import { HttpRequestInterceptor } from './services/http-interceptor.service';
// import { OneAuthService } from './services/one-auth.service';
import { OneAuthModule, OneAuthService } from 'one-auth';
import { CommonDialogComponent } from './modules/shared-components/components/dialog-box/common-dialog/common-dialog.component';
import { InitLoaderService } from './services/initloader.service';
import { StorageService } from './services/storage.service';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
    declarations: [
        AppComponent,
        // LoginComponent,
        PageNotFoundComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MaterialUiModule,
        SharedComponentsModule,
        AppToolbarsModule,
        ReactiveFormsModule,
        FormsModule,
        GridModule,
        TooltipsModule,
        // OneLoginModule,
        DevLoginModule,
        OneAuthModule.forRoot(environment),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        StorageService,
        OneAuthService,
        CommonDialogComponent,
        HttpRequestInterceptor,
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true
            }
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        RedirectGuard,
        InitLoaderService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}